.main {
    height: 100vh;
    width: 100%;
}

.nextButtonClass {
    border-radius: 12px;
    background: rgba(85,101,206,255);
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border: 0;
}

.inc {
    color: #666;
}

.createPaperHeading {
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.input {
    color: #000;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.inputbutton {
    border-radius: 5px;
    background: #F1F1F1;
    width: 5ch;
    border: none;
}

.inputbtn {
    border-radius: 5px;
    background: #F1F1F1;
    width: 12ch;
    border: none;
}

.addbtn {
    border-radius: 5px;
    background: #EDF7FF;
    color: #3774A5;
    padding: 10px 24px;
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 0;
}

.sectionadd {
    color: #000;

    text-align: center;
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.out {
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    background: #FFF;
}

.section-name {
    color: #999;
    /* border: 1px solid green; */
    text-align: center;
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* height: 30vh; */
    margin: auto;
}

.border {
    border-radius: 20px 20px 0px 0px;
    background: #FFF;
}

.addsecbtn {
    color: #3774A5;
    background-color: #EDF7FF;
    text-align: center;
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border: 0;
}

.min-plusbtn {
    border-radius: 5px;
    border: 1px solid #DDD;
    /* border: 1px solid blue; */
    margin: 1px;
    padding: 10px;
    background: #FFF;
}

.head {
    color: #000;

    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.nop {
    color: #565656;

    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.tc {
    color: #565656;

    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-top: 15px;
}
.contain {
    background: linear-gradient(180deg, #3675A8 0%, #2AA0FF 100%);
    height: 100vh;
}
.pcs {
    margin: 12px;
}
.total {
    border-radius: 35px 35px 0px 0px;
    background: #FFF;
    height: 60%;
    overflow-y: auto;
}
.upperdiv {
    height: 25%;
    color: #FFF;
    text-align: center;
    font-family: Archivo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    /* padding-top: 3rem; */
}
.hr {
    border: 1px solid grey;
}
.tq {
    color: #565656;
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.sets {
    color: #242424;
    font-family: Archivo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    border: 1px solid black;
    text-align: center;
}
.outline {
    border-radius: 10px;
    background: #FFF;
}
.action {
    color: #FF2525;
    text-align: center;
    font-family: Archivo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.cancel {
    border-radius: 5px;
    background: #dc1212;
    color: #FFF;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 30%;
    border: 0;
    padding: 15px;
    width: 80%;
}
.ahead {
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 30%;
    border: 1px solid black;
    padding: 15px;
    width: 80%;
    border-radius: 5px;
    background: #fff;
}

.mq{
    justify-content: center;
}

@media only screen and (max-width:568px) {
    .mq{
        justify-content:start;
    }
}